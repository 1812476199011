import { AwsRum } from 'aws-rum-web';
import configuration from '../configuration.json';

let awsRum : AwsRum | undefined;

interface Configuration {
  apiBaseUrl: string;
  resourcesBaseUrl: string;
  rum?: {
    applicationId: string;
    cognitoIdentityPoolId: string;
    region: string;
  };
}

const { rum } = configuration as Configuration;

if (rum) {
  try {
    const applicationVersion = '1.0.0';
    const applicationRegion = rum.region;
    const rumConfig = {
      sessionSampleRate: 1,
      identityPoolId: rum.cognitoIdentityPoolId,
      endpoint: `https://dataplane.rum.${applicationRegion}.amazonaws.com`,
      telemetries: ['performance', 'errors', 'http'],
      allowCookies: true,
      enableXRay: false,
    };

    awsRum = new AwsRum(
      rum.applicationId,
      applicationVersion,
      applicationRegion,
      rumConfig,
    );
  } catch (error) {
    console.error('Error loading AWS RUM configuration:', error);
  }
} else {
  console.log('AWS RUM is not enabled');
}

export function reportPageView(path: string) {
  if (awsRum) {
    awsRum.recordPageView(path);
  }
}
