import { SavedBook } from '../interfaces/SavedBook';

const LOCAL_STORAGE_KEYS = {
  BOOKS: 'JENNY_LIBRARY_BOOKS',
  PENDING: 'JENNY_LIBRARY_PENDING_BOOK_IDS',
};
const MAX_LIBRARY_SIZE = 100;
const TTL_MS = 179 * 24 * 60 * 60 * 1000; // 179 days (180 is server configured TTL)

export function getPendingBookIds(): {[bookId: string]: number} {
  const rawPendingBookIds = localStorage.getItem(LOCAL_STORAGE_KEYS.PENDING);
  if (!rawPendingBookIds) {
    return {};
  }
  let pendingBookIds;
  try {
    pendingBookIds = JSON.parse(rawPendingBookIds) as string[];
  } catch (e) {
    console.error('Error parsing pending book ids', e);
    return {};
  }

  return pendingBookIds.reduce((pendingBooks, bookId) => ({ ...pendingBooks, [bookId]: 1 }), {});
}

export function addPendingBookId(bookId: string) {
  const pendingBookMap = getPendingBookIds();
  if (pendingBookMap[bookId]) {
    return;
  }
  const pendingBookIds = Object.keys(pendingBookMap);
  pendingBookIds.push(bookId);
  localStorage.setItem(LOCAL_STORAGE_KEYS.PENDING, JSON.stringify(pendingBookIds));
}

export function removePendingBookId(bookId: string) {
  const pendingBookMap = getPendingBookIds();
  delete pendingBookMap[bookId];
  const pendingBookIds = Object.keys(pendingBookMap);
  localStorage.setItem(LOCAL_STORAGE_KEYS.PENDING, JSON.stringify(pendingBookIds));
}

export function getSavedBooks(): SavedBook[] {
  const rawBooks = localStorage.getItem(LOCAL_STORAGE_KEYS.BOOKS);
  if (!rawBooks) {
    return [];
  }

  let allBooks;
  try {
    allBooks = JSON.parse(rawBooks) as SavedBook[];
  } catch (e) {
    console.error('Error parsing saved books', e);
    return [];
  }

  const [books, bookIdsToRemove] = allBooks.reduce((prev, curr) => {
    if (curr.createdAtMs > Date.now() - TTL_MS) {
      prev[0].push(curr);
    } else {
      prev[1].push(curr.bookId);
    }
    return prev;
  }, [[], []] as [SavedBook[], string[]]);

  if (bookIdsToRemove.length > 0) {
    console.log('Removing books from library because they have expired', bookIdsToRemove);
    localStorage.setItem(LOCAL_STORAGE_KEYS.BOOKS, JSON.stringify(books));
  }

  return books;
}

export function saveBook(
  bookId: string,
  plot: string,
  title: string,
  pagesCount: number,
  amIAuthor: boolean,
) {
  const createdAtMs = Date.now();
  const book = {
    bookId, plot, title, pagesCount, amIAuthor, createdAtMs,
  };
  const books = getSavedBooks();
  books.push(book);
  if (books.length > MAX_LIBRARY_SIZE) {
    console.log('Library too big, removing oldest book from library');
    books.shift();
  }

  localStorage.setItem(LOCAL_STORAGE_KEYS.BOOKS, JSON.stringify(books));
}

export function doesBookExist(bookId: string) {
  const books = getSavedBooks();
  return books.some((book) => book.bookId === bookId);
}

export function removeBook(bookId: string) {
  const books = getSavedBooks();
  const newBooks = books.filter((book) => book.bookId !== bookId);
  localStorage.setItem(LOCAL_STORAGE_KEYS.BOOKS, JSON.stringify(newBooks));
}
