import React from 'react';
import { Button } from 'react-bootstrap';

import {
  FaWhatsapp, FaFacebook, FaLinkedin, FaEnvelope,
} from 'react-icons/fa';

export interface ShareChannel {
  name: string;
  icon: React.ReactNode;
  backgroundColor: string;
  linkGenerator: (title: string, href: string) => string;
}

export const SHARE_CHANNELS: ShareChannel[] = [
  {
    name: 'whatsapp',
    icon: <FaWhatsapp />,
    backgroundColor: '#25D366',
    linkGenerator: (title, href) => {
      const content = `I made a story using Jenny called "${title}"\n\nCheck it out here: ${href}`;
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(content)}`;
    },
  },
  {
    name: 'facebook',
    icon: <FaFacebook />,
    backgroundColor: '#4267B2',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    linkGenerator: (title, href) => `https://www.facebook.com/sharer.php?u=${encodeURIComponent(href)}`,
  },

  {
    name: 'linkedin',
    icon: <FaLinkedin />,
    backgroundColor: '#0077B5',
    linkGenerator: (title, href) => `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(href)}&title=${encodeURIComponent(title)}&description=${encodeURIComponent(`I made a story using Jenny called "${title}"`)}`,
  },
  {
    name: 'email',
    icon: <FaEnvelope />,
    backgroundColor: '#e3192d',
    linkGenerator: (title, href) => `mailto:?subject=${encodeURIComponent(`I made a story using Jenny called "${title}"`)}&body=${encodeURIComponent(`I made a story using Jenny called "${title}"\n\nCheck it out here: ${href}`)}`,
  },
];

export function shareStory(channel: ShareChannel, bookId: string, title: string): void {
  const href = `${window.location.origin}/book/${bookId}`;
  const url = channel.linkGenerator(title, href);
  window.open(url, '_blank');
}

export function renderButton(channel: ShareChannel, onClick: () => void): JSX.Element {
  return (
    <Button
      style={{ background: channel.backgroundColor, border: '0px' }}
      onClick={onClick}
    >
      {channel.icon}
    </Button>
  );
}
