import { Spinner } from 'react-bootstrap';

function Loading() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h5>Loading...</h5>
      <Spinner animation="border" variant="primary" />
    </div>
  );
}

export default Loading;
