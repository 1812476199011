import { useNavigate } from 'react-router-dom';
import {
  Accordion, Button, Container, FormControl,
  InputGroup, Modal, Row, Col,
  Stack,
} from 'react-bootstrap';
import { GiBookCover, GiMagicLamp } from 'react-icons/gi';
import { IoTrashOutline } from 'react-icons/io5';
import { BiSearch } from 'react-icons/bi';
import { useState, useEffect, useCallback } from 'react';
import Header from './Header';
import { APIClient } from '../lib/APIClient';
import { SavedBook } from '../interfaces/SavedBook';
import { getSavedBooks, removeBook } from '../lib/Library';
import Loading from './Loading';
import { SHARE_CHANNELS, shareStory, renderButton } from './Share';

const apiClient = new APIClient();

function msToReadableDelta(ms: number): string {
  const nowMs = Date.now();
  const deltaMs = nowMs - ms;
  const seconds = Math.floor(deltaMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}  ago`;
  }
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  return 'less than a minute ago';
}

function ViewLibrary() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [allBooks, setAllBooks] = useState<SavedBook[] | null>(null);
  const [filteredBooks, setFilteredBooks] = useState<SavedBook[] | null>(null);
  const [bookIdToRemove, setBookIdToRemove] = useState<string | null>(null);

  const refresh = () => {
    const allSavedBooks = getSavedBooks();
    allSavedBooks.sort((a, b) => (b.createdAtMs - a.createdAtMs));
    setAllBooks(allSavedBooks);
    setFilteredBooks(allSavedBooks);
  };

  const handleSearchTextChange = useCallback((newSearchText: string) => {
    setSearchText(newSearchText);
    const cleanSearchText = newSearchText.trim().toLowerCase();
    const books = (allBooks || []).filter((book) => {
      if (!cleanSearchText) {
        return true;
      }
      const haystack = `${book.title} ${book.plot} ${book.bookId}`;
      return haystack.toLowerCase().includes(cleanSearchText);
    });
    setFilteredBooks(books);
  }, [allBooks]);

  useEffect(() => {
    refresh();
  }, []);

  let body = <Loading />;

  if (filteredBooks && allBooks) {
    const totalBookCount = allBooks.length;
    let bookList = (
      <div style={{ textAlign: 'center' }}>
        <h4>Empty Library</h4>
        <p>It seems you don&apos;t have any stories saved.</p>
        <p>That&apos;s OK - use your imagination to create a new one!</p>
        <Button
          onClick={() => navigate('/')}
          variant="success"
          className="btn-lg"
        >
          <GiMagicLamp />
          {' '}
          New Story
        </Button>
      </div>
    );

    if (filteredBooks.length > 0) {
      bookList = (
        <Accordion>
          {
            filteredBooks.map((book) => (
              <Accordion.Item eventKey={book.bookId} key={book.bookId}>
                <Accordion.Header>{book.title}</Accordion.Header>
                <Accordion.Body>
                  <Container>
                    <Row>
                      <Col md="auto">
                        <img
                          src={apiClient.getPageImageUrl(book.bookId, 1)}
                          height="200"
                          alt="story cover"
                        />
                      </Col>
                      <Col>
                        <Stack style={{ height: '85%' }}>
                          <Stack direction="horizontal">
                            <div>{book.plot}</div>
                            <Stack direction="horizontal" className="ms-auto">
                              {
                                SHARE_CHANNELS.map((channel, index) => (
                                  <div key={channel.name} className={index ? 'ms-1' : 'ms-auto'}>
                                    {
                                      renderButton(
                                        channel,
                                        () => shareStory(channel, book.bookId, book.title),
                                      )
                                    }
                                  </div>
                                ))
                              }
                            </Stack>
                          </Stack>
                          <Button
                            variant="success"
                            className="me-auto m-2"
                            onClick={() => navigate(`/book/${book.bookId}`)}
                          >
                            <GiBookCover />
                            {' '}
                            Let&apos;s read
                          </Button>
                        </Stack>
                        <Stack direction="horizontal">
                          <div className="mt-2">
                            <i>
                              {book.amIAuthor ? 'Created by me' : 'Read by me'}
                              {' '}
                              {msToReadableDelta(book.createdAtMs)}
                            </i>
                          </div>
                          <Button
                            onClick={() => setBookIdToRemove(book.bookId)}
                            variant="danger"
                            size="sm"
                            className="ms-auto"
                          >
                            <IoTrashOutline />
                            {' '}
                            Remove Story
                          </Button>
                        </Stack>
                      </Col>
                    </Row>
                  </Container>
                </Accordion.Body>
              </Accordion.Item>
            ))
          }
        </Accordion>
      );
    }

    body = (
      <>
        <Modal centered show={!!bookIdToRemove} onHide={() => setBookIdToRemove(null)}>
          <Modal.Header closeButton>
            <Modal.Title>Remove Book</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove this story from your library?</p>
            <p>People that the story was shared with can still view it.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setBookIdToRemove(null)}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                removeBook(bookIdToRemove || '');
                setBookIdToRemove(null);
                refresh();
              }}
            >
              Remove
            </Button>
          </Modal.Footer>
        </Modal>
        <p>
          You have
          {` ${totalBookCount} ${totalBookCount === 1 ? 'story ' : 'stories '}`}
          in your library. Click on one below to get more details and read it again.
        </p>
        <InputGroup className="mb-3">
          <InputGroup.Text><BiSearch /></InputGroup.Text>
          <FormControl
            placeholder="Search library"
            value={searchText}
            onChange={(event) => handleSearchTextChange(event.target.value)}
          />
          <InputGroup.Text>
            showing
            {' '}
            {filteredBooks.length}
            /
            {totalBookCount}
          </InputGroup.Text>
        </InputGroup>
        {bookList}
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="m-2">
        <h1>Library</h1>
        {body}
      </div>
    </>
  );
}

export default ViewLibrary;
