import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootswatch/dist/sketchy/bootstrap.css';
import CreateBook from './components/CreateBook';
import ViewBook from './components/ViewBook';
import ViewLibrary from './components/ViewLibrary';

const router = createBrowserRouter([
  {
    path: '/book/:bookId',
    element: <ViewBook />,
  },
  {
    path: '/library',
    element: <ViewLibrary />,
  },
  {
    path: '/',
    element: <CreateBook />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<RouterProvider router={router} />);
