import { Navbar, Container, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { GiMagicLamp } from 'react-icons/gi';
import { IoLibraryOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { reportPageView } from '../lib/CloudwatchRum';

function Header() {
  const location = useLocation();

  useEffect(() => {
    reportPageView(location.pathname);
  }, [location]);

  return (
    <Navbar variant="dark" bg="primary">
      <Container fluid>
        <LinkContainer to="/">
          <Navbar.Brand>
            Jenny
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="bas-c-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>
                <GiMagicLamp style={{ fontSize: '30px' }} />
                {' '}
                New Story
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/library">
              <Nav.Link>
                <IoLibraryOutline style={{ fontSize: '30px' }} />
                {' '}
                Library
              </Nav.Link>
            </LinkContainer>
          </Nav>
          <Navbar.Text>
            <i className="m-2">
              {'Powered by '}
              <a href="https://aws.amazon.com/generative-ai" target="_blank" rel="noreferrer">Amazon Web Services</a>
            </i>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
