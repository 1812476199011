import { useState, useEffect } from 'react';
import {
  Container, Row, Col, InputGroup,
  FormControl, Button, Dropdown, DropdownButton,
  Accordion, AccordionBody, Stack,
} from 'react-bootstrap';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { GiMagicLamp } from 'react-icons/gi';
import Header from './Header';
import { APIClient } from '../lib/APIClient';
import Jenny from '../images/jenny.png';
import Loading from './Loading';
import { addPendingBookId } from '../lib/Library';

const DEFAULT_FIELD_DATA = {
  plot: '',
  characters: '',
  durationMinutes: '',
  childYearsOld: '',
  inspirationBook: '',
  inspirationAuthor: '',
  imageStylePresetIndex: 0,
};
const IMAGE_STYLE_PRESETS = [
  ['Digital Art', 'digital-art'],
  ['Photographic', 'photographic'],
  ['Cinematic', 'cinematic'],
  ['3D Model', '3d-model'],
  ['Analog Film', 'analog-film'],
  ['Comic Book', 'comic-book'],
  ['Fantasy Art', 'fantasy-art'],
  ['Line Art', 'line-art'],
  ['Origami', 'origami'],
  ['Tile Texture', 'tile-texture'],
];

const IMAGE_STYLE_PRESETS_INDEX_MAP = IMAGE_STYLE_PRESETS
  .reduce((prev, curr, index) => ({ ...prev, [curr[1]]: index }), {}) as {[key: string]: number};

const apiClient = new APIClient();

function CreateBook() {
  const navigate = useNavigate();
  const [fieldData, setFieldData] = useState(DEFAULT_FIELD_DATA);
  const [isLoading, setIsLoading] = useState(true);
  const [bookId, setBookId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const fromBookId = searchParams.get('fromBookId');

  useEffect(() => {
    new Promise((resolve, reject) => {
      if (fromBookId) {
        apiClient.getBookData(fromBookId).then((bookData) => {
          if (bookData) {
            setFieldData({
              plot: bookData.input.plot || '',
              characters: bookData.input.characters || '',
              durationMinutes: bookData.input.durationMinutes ? bookData.input.durationMinutes.toString() : '',
              childYearsOld: bookData.input.childYearsOld ? bookData.input.childYearsOld.toString() : '',
              inspirationBook: bookData.input.inspirationBook || '',
              inspirationAuthor: bookData.input.inspirationAuthor || '',
              imageStylePresetIndex: IMAGE_STYLE_PRESETS_INDEX_MAP[bookData.input.imageStylePreset],
            });
          } else {
            console.log(`Book with ID '${fromBookId}' not found`);
          }
          setIsLoading(false);
          resolve();
        }).catch(reject);
      } else {
        setIsLoading(false);
        resolve();
      }
    }) as Promise<void>;
  }, [fromBookId]);

  const handleFieldDataChange = (event: {target: { name: string, value: string | number }}) => {
    const { name, value } = event.target;
    setFieldData((prevFieldData) => ({
      ...prevFieldData,
      [name]: value,
    }));
  };

  const onSubmit = async () => {
    const {
      plot,
      characters,
      durationMinutes,
      childYearsOld,
      inspirationBook,
      inspirationAuthor,
      imageStylePresetIndex,
    } = fieldData;

    if (!plot || !plot.trim()) {
      alert('Must provide a plot');
      return;
    }

    const finalPlot = plot;
    let finalCharacters: string | null = null;
    let finalDurationMinutes: number | null = null;
    let finalChildYearsOld: number | null = null;
    let finalInspirationBook: string | null = null;
    let finalInspirationAuthor: string | null = null;

    if (characters && characters.trim()) {
      finalCharacters = characters.trim();
    }

    if (durationMinutes) {
      finalDurationMinutes = parseInt(durationMinutes, 10);
      if (Number.isNaN(finalDurationMinutes)) {
        alert('Duration minutes is an invalid number');
        return;
      }
    }

    if (childYearsOld) {
      finalChildYearsOld = parseInt(childYearsOld, 10);
      if (Number.isNaN(finalChildYearsOld)) {
        alert('Child age is an invalid number');
        return;
      }
    }

    if (inspirationBook && inspirationBook.trim()) {
      finalInspirationBook = inspirationBook.trim();
    }
    if (inspirationAuthor && inspirationAuthor.trim()) {
      finalInspirationAuthor = inspirationAuthor.trim();
    }

    const newBookId = await apiClient.createBook(
      finalPlot,
      finalCharacters,
      finalDurationMinutes,
      finalChildYearsOld,
      finalInspirationBook,
      finalInspirationAuthor,
      IMAGE_STYLE_PRESETS[imageStylePresetIndex][1],
    );

    setBookId(newBookId);

    addPendingBookId(newBookId);

    navigate(`/book/${newBookId}`);
  };

  let body = <Loading />;
  if (!isLoading) {
    body = (
      <>
        <InputGroup className="mb-2">
          <InputGroup.Text>Plot</InputGroup.Text>
          <FormControl
            value={fieldData.plot}
            placeholder="Enter the plot for your story"
            name="plot"
            onChange={handleFieldDataChange}
          />
        </InputGroup>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <i>Expand for more details (optional)</i>
            </Accordion.Header>
            <AccordionBody>
              <Stack>
                <InputGroup className="mb-2">
                  <InputGroup.Text>Characters</InputGroup.Text>
                  <FormControl
                    value={fieldData.characters}
                    placeholder="Who is your story about (separate with 'and')"
                    name="characters"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>Duration in Minutes</InputGroup.Text>
                  <FormControl
                    value={fieldData.durationMinutes}
                    placeholder="How long should your story be?"
                    name="durationMinutes"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>Child Age</InputGroup.Text>
                  <FormControl
                    value={fieldData.childYearsOld}
                    placeholder="How old (in years) is the target audience?"
                    name="childYearsOld"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>Inspired By Book</InputGroup.Text>
                  <FormControl
                    value={fieldData.inspirationBook}
                    placeholder="Title of book to use as inspiration"
                    name="inspirationBook"
                    onChange={handleFieldDataChange}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <InputGroup.Text>Image Style Preset</InputGroup.Text>
                  <DropdownButton
                    title={IMAGE_STYLE_PRESETS[fieldData.imageStylePresetIndex][0]}
                  >
                    {
                      IMAGE_STYLE_PRESETS.map((imageStylePreset, index) => (
                        <Dropdown.Item
                          key={imageStylePreset[1]}
                          onClick={
                            () => handleFieldDataChange(
                              {
                                target: {
                                  name: 'imageStylePresetIndex',
                                  value: index,
                                },
                              },
                            )
                          }
                        >
                          {imageStylePreset[0]}
                        </Dropdown.Item>
                      ))
                    }
                  </DropdownButton>
                </InputGroup>
              </Stack>
            </AccordionBody>
          </Accordion.Item>
        </Accordion>
        <Button
          variant="success"
          onClick={onSubmit}
          disabled={!!bookId}
          className="mt-3 btn-lg"
        >
          <GiMagicLamp />
          {' Create Story'}
        </Button>
      </>
    );
  }

  return (
    <div className="CreateBook">
      <Header />
      <div className="m-2">
        <h1>Create a new book</h1>
        <Container fluid>
          <Row>
            <Col md="auto">
              <img src={Jenny} alt="Jenny" width="300" />
            </Col>
            <Col>{body}</Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default CreateBook;
